import { REQUEST_HTMLS, RECEIVE_HTMLS } from '../html'
import { REQUEST_PROPERTIES, RECEIVE_PROPERTIES } from '../property'
import { REQUEST_PROJECT, RECEIVE_PROJECT } from '../project'
import {
  REQUEST_CREATE_INTEREST_SIGNUP,
  RECEIVE_CREATE_INTEREST_SIGNUP,
  REQUEST_CREATE_SALES_START_SIGNUP,
  RECEIVE_CREATE_SALES_START_SIGNUP,
  REQUEST_UPDATE_SALES_START_SIGNUP,
  RECEIVE_UPDATE_SALES_START_SIGNUP,
  REQUEST_GET_USER,
  RECEIVE_GET_USER,
  REQUEST_SALES_START_PREREGISTER,
  RECEIVE_SALES_START_PREREGISTER,
} from '../user'
import { REQUEST_CREATE_INTERESTS, RECEIVE_CREATE_INTERESTS } from '../interest'
import { REQUEST_SHOWINGS, RECEIVE_SHOWINGS } from '../showing'

const initialState = {
  createInterestSignup: false,
  createSalesStartSignup: false,
  createInterests: false,
  getHtmls: false,
  getProject: false,
  getProperties: false,
  getShowings: false,
  getUser: false,
  updateSalesStartSignup: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_HTMLS:
      return {
        ...state,
        ...{ getHtmls: true },
      }
    case RECEIVE_HTMLS:
      return {
        ...state,
        ...{ getHtmls: false },
      }
    case REQUEST_PROJECT:
      return {
        ...state,
        ...{ getProject: true },
      }
    case RECEIVE_PROJECT:
      return {
        ...state,
        ...{ getProject: false },
      }
    case REQUEST_PROPERTIES:
      return {
        ...state,
        ...{ getProperties: true },
      }
    case RECEIVE_PROPERTIES:
      return {
        ...state,
        ...{ getProperties: false },
      }
    case REQUEST_CREATE_INTEREST_SIGNUP:
      return {
        ...state,
        ...{ createInterestSignup: true },
      }
    case RECEIVE_CREATE_INTEREST_SIGNUP:
      return {
        ...state,
        ...{ createInterestSignup: false },
      }
    case REQUEST_CREATE_SALES_START_SIGNUP:
      return {
        ...state,
        ...{ createSalesStartSignup: true },
      }
    case RECEIVE_CREATE_SALES_START_SIGNUP:
      return {
        ...state,
        ...{ createInterestSignup: false, createSalesStartSignup: false },
      }
    case REQUEST_GET_USER:
      return {
        ...state,
        ...{ getUser: true },
      }
    case RECEIVE_GET_USER:
      return {
        ...state,
        ...{ getUser: false },
      }
    case REQUEST_UPDATE_SALES_START_SIGNUP:
    case REQUEST_SALES_START_PREREGISTER:
      return {
        ...state,
        ...{ updateSalesStartSignup: true },
      }
    case RECEIVE_UPDATE_SALES_START_SIGNUP:
    case RECEIVE_SALES_START_PREREGISTER:
      return {
        ...state,
        ...{ updateSalesStartSignup: false },
      }
    case REQUEST_CREATE_INTERESTS:
      return {
        ...state,
        ...{ createInterests: true },
      }
    case RECEIVE_CREATE_INTERESTS:
      return {
        ...state,
        ...{ createInterests: false },
      }
    case REQUEST_SHOWINGS:
      return {
        ...state,
        ...{ getShowings: true },
      }
    case RECEIVE_SHOWINGS:
      return {
        ...state,
        ...{ getShowings: false },
      }
    default:
      return state
  }
}

export default reducer
