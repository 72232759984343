import { createAction } from 'redux-actions'
import { request } from '../../api'
import { put, takeLatest } from 'redux-saga/effects'

export const REQUEST_CREATE_SALES_START_SIGNUP =
  'Request create sales start signup'
export const RECEIVE_CREATE_SALES_START_SIGNUP =
  'Receive create sales start signup'
export const REQUEST_UPDATE_SALES_START_SIGNUP =
  'Request update sales start signup'
export const RECEIVE_UPDATE_SALES_START_SIGNUP =
  'Receive update sales start signup'
export const REQUEST_CREATE_INTEREST_SIGNUP = 'Request create interest signup'
export const RECEIVE_CREATE_INTEREST_SIGNUP = 'Receive create interest signup'
export const REQUEST_GET_USER = 'Request get user'
export const RECEIVE_GET_USER = 'Receive get user'
export const RECEIVE_USER_SOURCE = 'Receive user source'
export const REQUEST_SALES_START_PREREGISTER = 'Request sales start preregister'
export const RECEIVE_SALES_START_PREREGISTER = 'Receive sales start preregister'

export const requestCreateInterestSignup = createAction(
  REQUEST_CREATE_INTEREST_SIGNUP,
  (projectId, user) => ({ projectId, user })
)
export const receiveCreateInterestSignup = createAction(
  RECEIVE_CREATE_INTEREST_SIGNUP
)
export const requestCreateSalesStartSignup = createAction(
  REQUEST_CREATE_SALES_START_SIGNUP,
  (projectId) => ({ projectId })
)
export const receiveCreateSalesStartSignup = createAction(
  RECEIVE_CREATE_SALES_START_SIGNUP
)
export const requestGetUser = createAction(
  REQUEST_GET_USER,
  (projectId, email) => ({ projectId, email })
)
export const receiveGetUser = createAction(RECEIVE_GET_USER)
export const requestUpdateSalesStartSignup = createAction(
  REQUEST_UPDATE_SALES_START_SIGNUP,
  (auth, user) => ({ auth, user })
)
export const receiveUpdateSalesStartSignup = createAction(
  RECEIVE_UPDATE_SALES_START_SIGNUP
)
export const receiveUserSource = createAction(RECEIVE_USER_SOURCE)
export const requestSalesStartPreregister = createAction(
  REQUEST_SALES_START_PREREGISTER,
  (projectId, userId) => ({ projectId, userId })
)
export const receiveSalesStartPreregister = createAction(
  RECEIVE_SALES_START_PREREGISTER
)

function* sagaCreateInterestSignup({ payload }) {
  try {
    const data = yield request({
      method: 'POST',
      url: `/api/user/interest-signup`,
      body: { projectId: payload.projectId, user: payload.user },
    })
    yield put(receiveCreateInterestSignup(data))
  } catch (error) {
    console.error(error)
    yield put(receiveCreateInterestSignup(error))
  }
}

function* sagaCreateSalesStartSignup({ payload }) {
  try {
    const data = yield request({
      method: 'POST',
      url: `/api/user/sales-signup`,
      body: { projectId: payload.projectId },
    })
    yield put(receiveCreateSalesStartSignup(data))
  } catch (error) {
    console.error(error)
    yield put(receiveCreateSalesStartSignup(error))
  }
}

function* sagaGetUser({ payload }) {
  try {
    const data = yield request({
      method: 'GET',
      url: `/api/user/projects/${payload.projectId}/users?email=${payload.email}`,
    })
    yield put(receiveGetUser(data))
  } catch (error) {
    console.error(error)
    yield put(receiveGetUser(error))
  }
}

function* sagaUpdateSalesStartSignup({ payload }) {
  try {
    const data = yield request({
      method: 'PUT',
      url: `/api/user/sales-signup`,
      body: payload.user,
      headers: { Authorization: `Bearer ${payload.auth.jwtToken}` },
    })

    yield put(receiveUpdateSalesStartSignup(data))
  } catch (error) {
    console.error(error)
    yield put(receiveUpdateSalesStartSignup(error))
  }
}

function* sagaSalesStartPreRegister({ payload }) {
  try {
    const data = yield request({
      method: 'PUT',
      url: `/api/user/projects/${payload.projectId}/users/${payload.userId}/sales-start-pre-register`,
    })
    yield put(receiveSalesStartPreregister(data))
  } catch (error) {
    console.error(error)
    yield put(receiveSalesStartPreregister(error))
  }
}

export function* sagas() {
  yield takeLatest(requestCreateInterestSignup, sagaCreateInterestSignup)
  yield takeLatest(requestCreateSalesStartSignup, sagaCreateSalesStartSignup)
  yield takeLatest(requestGetUser, sagaGetUser)
  yield takeLatest(requestUpdateSalesStartSignup, sagaUpdateSalesStartSignup)
  yield takeLatest(requestSalesStartPreregister, sagaSalesStartPreRegister)
}

const initialState = {
  acceptPrivacyPolicy: false,
  acceptTracking: false,
  city: '',
  email: '',
  firstName: '',
  lastName: '',
  membership: false,
  salesStartPreRegister: false,
  personalNumber: '',
  birthYear: '',
  phone: '',
  street: '',
  zip: '',
}

const reducer = (state = initialState, action) => {
  const { error, payload, type } = action

  switch (type) {
    case RECEIVE_CREATE_SALES_START_SIGNUP:
    case RECEIVE_CREATE_INTEREST_SIGNUP:
    case RECEIVE_UPDATE_SALES_START_SIGNUP:
      return {
        ...state,
        ...payload,
      }
    case RECEIVE_SALES_START_PREREGISTER:
    case RECEIVE_GET_USER:
      if (error) {
        return {}
      } else {
        return {
          ...state,
          ...payload,
        }
      }
    case RECEIVE_USER_SOURCE:
      return {
        ...state,
        source: payload,
      }
    default:
      return state
  }
}

export default reducer
