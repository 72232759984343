import { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { requestGetUser, requestSalesStartPreregister } from '../../redux/user'
import Html from '../html'
import { formatDateTime } from '../../helper'
import { isValidEmail } from '../../validation'
import { useTranslation } from 'react-i18next'

const SearchResult = ({ projectId, user, isRegistering, onSignup }) => {
  const { t } = useTranslation()

  if (!user?.firstName) {
    return (
      <Stack spacing={2} alignItems="start">
        <Typography>
          {t(
            'No signup found for that email address. To confirm your registration, you must first register your interest.'
          )}
        </Typography>
        <Button component={Link} to={`/projekt/${projectId}`}>
          {t('You can do that here')}
        </Button>
      </Stack>
    )
  }

  return (
    <Box>
      <Stack spacing={2} alignItems="start">
        <Box sx={{ p: 2, backgroundColor: grey[100], width: '100%' }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography>
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography variant="caption">
              {formatDateTime(user?.createdAt)}
            </Typography>
          </Stack>
          <Typography>{user?.street}</Typography>
          <Typography>
            {user?.zip} {user?.city}
          </Typography>
          <Typography>{user?.phone}</Typography>
          <Typography>{user?.email}</Typography>
          <Typography>
            {t('Registration confirmed: ')}
            {user?.salesStartPreRegistered ? t('Yes') : t('No')}
          </Typography>
        </Box>
        <Button
          variant="contained"
          disabled={user?.salesStartPreRegistered || isRegistering}
          onClick={onSignup}
        >
          {isRegistering ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            <Typography component="span" variant="button">
              {user?.salesStartPreRegistered
                ? t('Confirmation received')
                : t('Confirm')}
            </Typography>
          )}
        </Button>
      </Stack>
    </Box>
  )
}

export const PhysicalSalesStartSignupFormComponent = ({
  projectId,
  isSearching,
  isRegistering,
  user = {},
  onSearch,
  onSignup,
}) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [hasSearched, setHasSearched] = useState(false)

  const handleChangeEmail = (ev) => {
    ev.preventDefault()
    setEmail(ev.target.value)
  }

  const handleSearch = (ev) => {
    ev.preventDefault()
    if (isValidEmail(email)) {
      setEmailError(false)
      setHasSearched(true)
      onSearch(projectId, email.trim())
    } else {
      setEmailError(true)
      setHasSearched(false)
    }
  }

  const handleSignup = (e) => {
    e.preventDefault()
    onSignup(projectId, user.id)
  }

  return (
    <Stack component="form" onSubmit={handleSearch} spacing={3}>
      <Html name="sales-start-signup" />
      <Stack spacing={1} alignItems="start">
        <TextField
          fullWidth
          variant="filled"
          label={t('Email address')}
          name="email"
          value={email}
          type="email"
          onChange={handleChangeEmail}
          error={emailError}
          helperText={emailError && t('Enter email address')}
        />
        <Button
          variant="contained"
          disabled={isSearching}
          type="submit"
          onSubmit={handleSearch}
        >
          {isSearching ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            <Typography component="span" variant="button">
              {t('Search')}
            </Typography>
          )}
        </Button>
      </Stack>
      {!isSearching && hasSearched && (
        <SearchResult
          projectId={projectId}
          user={user}
          isRegistering={isRegistering}
          onSignup={handleSignup}
        />
      )}
    </Stack>
  )
}

const mapStateToProps = ({ request, user }, { match }) => {
  return {
    isSearching: request.getUser,
    isRegistering: request.updateSalesStartSignup,
    projectId: Number(match.params.projectId),
    user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSearch: (projectId, email) => dispatch(requestGetUser(projectId, email)),
    onSignup: (projectId, userId) =>
      dispatch(requestSalesStartPreregister(projectId, userId)),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PhysicalSalesStartSignupFormComponent)
)
