import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import {
  isValidEmail,
  isValidFirstName,
  isValidLastName,
  isValidOrganizationName,
  isValidOrganizationNumber,
  isValidPhone,
  isValidZip,
  isValidBirthYear,
} from '../../validation'
import { requestCreateInterestSignup } from '../../redux/user'
import PrivacyPolicy from '../privacyPolicy'
import Html from '../html'
import EmailField from '../EmailField'
import { useTranslation } from 'react-i18next'
import * as helper from './helper'

const isValid = (prop) => {
  const [name, value] = Object.entries(prop)[0]
  switch (name) {
    case 'firstName':
      return isValidFirstName(value)
    case 'lastName':
      return isValidLastName(value)
    case 'email':
      return isValidEmail(value)
    case 'organizationName':
      return isValidOrganizationName(value)
    case 'organizationNumber':
      return isValidOrganizationNumber(value)
    case 'phone':
      return isValidPhone(value)
    case 'zip':
      return isValidZip(value)
    case 'birthYear':
      return isValidBirthYear(value)
    case 'acceptPrivacyPolicy':
      return !!value
    default:
      return true
  }
}

const isCompanyValid = ({
  firstName,
  lastName,
  organizationNumber,
  organizationName,
  email,
  phone,
  street,
  zip,
  city,
  acceptPrivacyPolicy,
}) => {
  return (
    isValid({ firstName }) &&
    isValid({ lastName }) &&
    isValid({ organizationNumber }) &&
    isValid({ organizationName }) &&
    isValid({ email }) &&
    isValid({ phone }) &&
    isValid({ street }) &&
    isValid({ zip }) &&
    isValid({ city }) &&
    isValid({ acceptPrivacyPolicy })
  )
}

const isUserValid = ({
  firstName,
  lastName,
  birthYear,
  email,
  phone,
  street,
  zip,
  city,
  acceptPrivacyPolicy,
}) => {
  return (
    isValid({ firstName }) &&
    isValid({ lastName }) &&
    isValid({ birthYear }) &&
    isValid({ email }) &&
    isValid({ phone }) &&
    isValid({ street }) &&
    isValid({ zip }) &&
    isValid({ city }) &&
    isValid({ acceptPrivacyPolicy })
  )
}

const InterestSignupForm = ({ user = {} }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const facebookPixelId = useSelector(
    (state) => state?.company?.facebookPixelId
  )
  const loading = useSelector((state) => state?.request?.createInterestSignup)
  const project = useSelector((state) => state?.project)
  const [state, setState] = useState(user)
  const [location, setLocation] = useState(undefined)
  const [referrer, setReferrer] = useState(undefined)
  const [touched, setTouched] = useState({})

  useEffect(() => {
    // Using helper to enable stubbing in test
    setLocation(helper.getWindowLocationHref())
  }, [])

  useEffect(() => {
    // Using helper to enable stubbing in test
    setReferrer(helper.getDocumentReferrer())
  }, [])

  const getMembership = () => {
    if (state.membership) {
      return 'membership'
    } else if (state.secondMembership) {
      return 'secondMembership'
    } else if (state.thirdMembership) {
      return 'thirdMembership'
    }
    return ''
  }

  const setMembership = (val) => {
    setState({
      ...state,
      membership: val === 'membership',
      secondMembership: val === 'secondMembership',
      thirdMembership: val === 'thirdMembership',
    })
  }

  const defaultBuyer = project?.allowedBuyers?.[0] || 'PRIVATE'

  const [isOrganization, setOrganization] = useState(defaultBuyer !== 'PRIVATE')

  function handleChange(event) {
    const { name, value } = event.target
    setState({ ...state, [name]: value })
  }

  function handleCheck(event) {
    const { name, checked } = event.target
    setState({ ...state, [name]: checked })
  }

  function handleBlur(event) {
    const { name } = event.target
    setTouched({ ...touched, [name]: true })
  }

  function handleCompanySubmit(event) {
    event.preventDefault()
    if (loading) {
      return
    }

    if (!isCompanyValid(state)) {
      return setTouched({
        firstName: true,
        lastName: true,
        organizationNumber: true,
        organizationName: true,
        email: true,
        phone: true,
        street: true,
        zip: true,
        city: true,
        acceptPrivacyPolicy: true,
        membership: true,
      })
    }

    if (
      project?.membership &&
      project?.threeMemberships &&
      getMembership() === ''
    ) {
      return setTouched({
        membership: true,
      })
    }

    delete state.birthYear

    dispatch(
      requestCreateInterestSignup(project.id, { ...state, location, referrer })
    )
  }

  function handleUserSubmit(event) {
    event.preventDefault()
    if (loading) {
      return
    }

    if (!isUserValid(state)) {
      return setTouched({
        firstName: true,
        lastName: true,
        birthYear: true,
        email: true,
        phone: true,
        street: true,
        zip: true,
        city: true,
        acceptPrivacyPolicy: true,
        membership: true,
      })
    }

    if (
      project?.membership &&
      project?.threeMemberships &&
      getMembership() === ''
    ) {
      return setTouched({
        membership: true,
      })
    }

    delete state.organizationNumber
    delete state.organizationName

    dispatch(
      requestCreateInterestSignup(project.id, { ...state, location, referrer })
    )
  }

  return (
    <Stack
      spacing={3}
      component="form"
      onSubmit={isOrganization ? handleCompanySubmit : handleUserSubmit}
    >
      <Html name="personal-details" />
      <Stack spacing={1}>
        {project?.allowedBuyers?.length > 1 && (
          <FormControlLabel
            label={
              defaultBuyer === 'PRIVATE'
                ? t('I represent an organization')
                : t("I'm an individual")
            }
            value={
              defaultBuyer === 'PRIVATE' ? isOrganization : !isOrganization
            }
            onChange={(e) =>
              setOrganization(
                defaultBuyer === 'PRIVATE'
                  ? e.target.checked
                  : !e.target.checked
              )
            }
            control={
              <Checkbox
                name="privateOrCompany"
                checked={
                  defaultBuyer === 'PRIVATE' ? isOrganization : !isOrganization
                }
              />
            }
          />
        )}
        <Grid container spacing={1}>
          {isOrganization && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="filled"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('Organization name')}
                  name="organizationName"
                  autoComplete="organization"
                  value={state?.organizationName || ''}
                  error={
                    touched.organizationName &&
                    !isValid({ organizationName: state?.organizationName })
                  }
                  helperText={
                    touched.organizationName &&
                    !isValid({ organizationName: state?.organizationName }) &&
                    t('Enter organization name')
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="filled"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label={t('Organization number')}
                  name="organizationNumber"
                  autoComplete="given-organization-number"
                  value={state?.organizationNumber || ''}
                  error={
                    touched.organizationNumber &&
                    !isValid({ organizationNumber: state?.organizationNumber })
                  }
                  helperText={
                    touched.organizationNumber &&
                    !isValid({
                      organizationNumber: state?.organizationNumber,
                    }) &&
                    t('Enter organization number')
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="filled"
              onChange={handleChange}
              onBlur={handleBlur}
              label={
                isOrganization
                  ? t('Contact person first name')
                  : t('First name')
              }
              name="firstName"
              autoComplete="given-name"
              value={state?.firstName || ''}
              error={
                touched.firstName && !isValid({ firstName: state?.firstName })
              }
              helperText={
                touched.firstName &&
                !isValid({ firstName: state?.firstName }) &&
                t('Enter first name')
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="filled"
              onChange={handleChange}
              onBlur={handleBlur}
              label={
                isOrganization ? t('Contact person last name') : t('Last name')
              }
              name="lastName"
              autoComplete="family-name"
              value={state?.lastName || ''}
              error={
                touched.lastName && !isValid({ lastName: state?.lastName })
              }
              helperText={
                touched.lastName &&
                !isValid({ lastName: state?.lastName }) &&
                t('Enter last name')
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EmailField
              onChange={handleChange}
              onBlur={handleBlur}
              email={state?.email}
              touched={touched.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="filled"
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('Phone number')}
              name="phone"
              autoComplete="phone"
              type="tel"
              value={state?.phone || ''}
              error={touched.phone && !isValid({ phone: state?.phone })}
              helperText={
                touched.phone &&
                !isValid({ phone: state?.phone }) &&
                t('Enter phone number')
              }
            />
          </Grid>
          <Grid item xs={12} sm={isOrganization ? 6 : 3}>
            <TextField
              fullWidth
              variant="filled"
              onChange={handleChange}
              onBlur={handleBlur}
              label={t('Postal code')}
              name="zip"
              type="tel"
              value={state?.zip || ''}
              error={touched.zip && !isValid({ zip: state?.zip })}
              helperText={
                touched.zip &&
                !isValid({ zip: state?.zip }) &&
                t('Enter postal code')
              }
            />
          </Grid>
          {!isOrganization && (
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('Year of birth')}
                placeholder={t('yyyy')}
                name="birthYear"
                type="tel"
                value={state?.birthYear || ''}
                error={
                  touched.birthYear && !isValid({ birthYear: state?.birthYear })
                }
                helperText={
                  touched.birthYear && !isValid({ birthYear: state?.birthYear })
                    ? t('Enter year of birth (yyyy)')
                    : t('Optional')
                }
              />
            </Grid>
          )}
        </Grid>
        {project?.membership && project?.threeMemberships && (
          <RadioGroup
            name="membership1"
            value={getMembership()}
            onChange={(e) => setMembership(e.target.value)}
          >
            <FormControlLabel
              value="membership"
              control={<Radio />}
              label={<Html name="membership" />}
            />
            <FormControlLabel
              value="secondMembership"
              control={<Radio />}
              label={<Html name="second-membership" />}
            />
            <FormControlLabel
              value="thirdMembership"
              control={<Radio />}
              label={<Html name="third-membership" />}
            />
            {touched.membership && getMembership() === '' && (
              <FormHelperText error={true}>
                {t('Please fill in the membership options')}
              </FormHelperText>
            )}
          </RadioGroup>
        )}
        {project?.membership && !project?.threeMemberships && (
          <Stack>
            <FormControlLabel
              name="membership"
              control={
                <Checkbox
                  name="membership"
                  checked={state?.membership ? true : false}
                  onChange={handleCheck}
                />
              }
              label={<Html name="membership" />}
            />
          </Stack>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2, backgroundColor: grey[100] }}
        >
          <Stack spacing={1}>
            {facebookPixelId && (
              <FormControlLabel
                control={
                  <Checkbox
                    name="acceptTracking"
                    checked={state?.acceptTracking ? true : false}
                    onChange={handleCheck}
                  />
                }
                label={t('I accept tracking for marketing purposes (optional)')}
              />
            )}
            <FormControl
              required
              error={
                touched.acceptPrivacyPolicy &&
                !isValid({ acceptPrivacyPolicy: state?.acceptPrivacyPolicy })
              }
              component="fieldset"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="acceptPrivacyPolicy"
                    checked={state?.acceptPrivacyPolicy ? true : false}
                    onChange={handleCheck}
                    onBlur={handleBlur}
                  />
                }
                label={t(
                  'I have read the information about how my personal data is processed'
                )}
              />
              {touched.acceptPrivacyPolicy &&
                !isValid({
                  acceptPrivacyPolicy: state?.acceptPrivacyPolicy,
                }) && (
                  <FormHelperText>
                    {t('Please accept our personal data policy')}
                  </FormHelperText>
                )}
            </FormControl>
          </Stack>
          <PrivacyPolicy />
        </Stack>
      </Stack>
      <Stack direction="row" spacing={3} alignItems="center">
        <Button
          variant="contained"
          type="submit"
          onSubmit={isOrganization ? handleCompanySubmit : handleUserSubmit}
        >
          {t('Continue')}
        </Button>
        {loading && <CircularProgress size={20} />}
      </Stack>
    </Stack>
  )
}

export default InterestSignupForm
