import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DownloadIcon from '@mui/icons-material/Download'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import Chip from './chip'
import { formatMoney, formatArea, getComparator } from './utils'

const TableComponent = ({ fields, onClick, properties }) => {
  const { t } = useTranslation()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('number')

  const handleClickSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (prop) => {
    onClick(prop)
  }

  const sorted = properties?.sort?.(getComparator(order, orderBy))

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'number'}
                direction={orderBy === 'number' ? order : 'asc'}
                onClick={() => {
                  handleClickSort('number')
                }}
              >
                {t('Number')}
              </TableSortLabel>
            </TableCell>
            {fields?.includes('BUILDING') && (
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'building'}
                  direction={orderBy === 'building' ? order : 'asc'}
                  onClick={() => {
                    handleClickSort('building')
                  }}
                >
                  {t('Building')}
                </TableSortLabel>
              </TableCell>
            )}
            {fields?.includes('ROOMS') && (
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'rooms'}
                  direction={orderBy === 'rooms' ? order : 'asc'}
                  onClick={() => {
                    handleClickSort('rooms')
                  }}
                >
                  {t('Rooms')}
                </TableSortLabel>
              </TableCell>
            )}
            {fields?.includes('AREA') && (
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'area'}
                  direction={orderBy === 'area' ? order : 'asc'}
                  onClick={() => {
                    handleClickSort('area')
                  }}
                >
                  {t('Area')}
                </TableSortLabel>
              </TableCell>
            )}
            {fields?.includes('FLOOR') && (
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'floor'}
                  direction={orderBy === 'floor' ? order : 'asc'}
                  onClick={() => {
                    handleClickSort('floor')
                  }}
                >
                  {t('Floor')}
                </TableSortLabel>
              </TableCell>
            )}
            {fields?.includes('PRICE') && (
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'price'}
                  direction={orderBy === 'price' ? order : 'asc'}
                  onClick={() => {
                    handleClickSort('price')
                  }}
                >
                  {t('Price')}
                </TableSortLabel>
              </TableCell>
            )}
            {fields?.includes('MONTHLY_FEE') && (
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'monthlyFee'}
                  direction={orderBy === 'monthlyFee' ? order : 'asc'}
                  onClick={() => {
                    handleClickSort('monthlyFee')
                  }}
                >
                  {t('Monthly fee')}
                </TableSortLabel>
              </TableCell>
            )}
            {fields?.includes('FLOOR_PLAN_URL') && (
              <TableCell>{t('Floor plan')}</TableCell>
            )}
            {fields?.includes('STATUS') && (
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'status'}
                  direction={orderBy === 'status' ? order : 'asc'}
                  onClick={() => {
                    handleClickSort('status')
                  }}
                >
                  {t('Status')}
                </TableSortLabel>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sorted?.map((p) => (
            <TableRow key={p.id}>
              <TableCell
                sx={onClick && { cursor: 'pointer' }}
                onClick={() => onClick && handleClick(p)}
              >
                {p.number}
              </TableCell>
              {fields?.includes('BUILDING') && (
                <TableCell
                  sx={onClick && { cursor: 'pointer' }}
                  onClick={() => onClick && handleClick(p)}
                >
                  {p.building}
                </TableCell>
              )}
              {fields?.includes('ROOMS') && (
                <TableCell
                  sx={onClick && { cursor: 'pointer' }}
                  onClick={() => onClick && handleClick(p)}
                >
                  {p.rooms}
                </TableCell>
              )}
              {fields?.includes('AREA') && (
                <TableCell
                  sx={onClick && { cursor: 'pointer' }}
                  onClick={() => onClick && handleClick(p)}
                >
                  {formatArea(p.area)}
                </TableCell>
              )}
              {fields?.includes('FLOOR') && (
                <TableCell
                  sx={onClick && { cursor: 'pointer' }}
                  onClick={() => onClick && handleClick(p)}
                >
                  {p.floor}
                </TableCell>
              )}
              {fields?.includes('PRICE') && (
                <TableCell
                  sx={onClick && { cursor: 'pointer' }}
                  onClick={() => onClick && handleClick(p)}
                >
                  {formatMoney(p.price)}
                </TableCell>
              )}
              {fields?.includes('MONTHLY_FEE') && (
                <TableCell
                  sx={onClick && { cursor: 'pointer' }}
                  onClick={() => onClick && handleClick(p)}
                >
                  {formatMoney(p.monthlyFee)}
                </TableCell>
              )}
              {fields?.includes('FLOOR_PLAN_URL') && (
                <TableCell>
                  {p.floorPlanUrl && (
                    <IconButton
                      size="small"
                      aria-label="download"
                      href={p.floorPlanUrl}
                      target="_blank"
                    >
                      <DownloadIcon />
                    </IconButton>
                  )}
                </TableCell>
              )}
              {fields?.includes('STATUS') && (
                <TableCell
                  sx={onClick && { cursor: 'pointer' }}
                  onClick={() => onClick && handleClick(p)}
                >
                  <Chip
                    status={p.status}
                    sx={onClick && { cursor: 'pointer' }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableComponent
