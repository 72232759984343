async function parseJSON(response) {
  const data = await response.json()
  const auth = response.headers.get('Authorization')
  if (auth) {
    data.jwtToken = auth.split('Bearer ')[1]
  }
  if (!response.ok) {
    throw Error(data?.message || response.statusText)
  }
  return data
}

export const request = (obj) => {
  return new Promise((resolve, reject) => {
    const { url, method, headers, body } = obj || {}

    fetch(url, {
      method: method,
      headers: {
        ...{
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '-1',
        },
        ...headers,
      },
      body: JSON.stringify(body),
    })
      .then(parseJSON)
      .then(function (data) {
        resolve(data)
      })
      .catch(function (error) {
        console.error(error)
        reject(error)
      })
  })
}

export const getUsersByPersonalNumber = (projectId, personalNumber) => {
  return request({
    method: 'GET',
    url: `/api/user/projects/${projectId}/users?personal-number=${personalNumber}`,
  })
}

export const salesStartPreRegister = (projectId, userId) => {
  return request({
    method: 'PUT',
    url: `/api/user/projects/${projectId}/users/${userId}/sales-start-pre-register`,
  })
}

export const sendSalesStartSignupEmail = (projectId, userId = 0) => {
  return request({
    method: 'PUT',
    url: `/api/user/projects/${projectId}/users/${userId}/send-sales-start-signup-email`,
  })
}
